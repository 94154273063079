<template>
  <td :colspan="colspan">
    <slot></slot>
    <v-row class="py-6">
      <template v-for="header in headers">
        <v-col :key="header.value" cols="12" sm="6" class="pt-0">
          <span class="text-subtitle-2">
            <slot :name="`header.${header.value}`">{{ header.text }}</slot>
          </span>
          <br />
          <slot :name="`item.${header.value}`">
            <span v-if="$getProperty(item, header.value)">{{
              $getProperty(item, header.value)
            }}</span>
            <span v-else>
              -
            </span>
          </slot>
        </v-col>
      </template>
    </v-row>
    <slot name="append"></slot>
  </td>
</template>

<script>
export default {
  name: 'BaseExpandedTableRow',

  props: {
    headers: {
      type: Array,
      default: () => [],
    },

    colspan: {
      type: Number,
      default: 1,
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
