/* eslint no-param-reassign: ["error", { "props": false }] */

// Some general table code that dries up table components.
// Usage example can be found in UserTable.vue

const tableMixin = {
  data() {
    return {
      tableRowKey: 'id', // override if it's different
      tableMixin_expandedRows: [],
    };
  },

  computed: {
    headers() {
      return []; // override in component
    },

    tableMixin_displayedHeaders() {
      const displayedHeaders = this.headers.filter((h) => !this.$vuetify.breakpoint[h.hidden]);
      if (this.hasActions) {
        // should be defined in props
        displayedHeaders.push({ value: 'actions', align: 'end' });
      }
      return displayedHeaders;
    },

    tableMixin_hiddenHeaders() {
      return this.headers.filter((h) => this.$vuetify.breakpoint[h.hidden]);
    },

    tableMixin_firstExpandedRowKey() {
      return this.tableMixin_expandedRows.length
        ? this.tableMixin_expandedRows[0][this.tableRowKey]
        : null;
    },
  },

  methods: {
    tableMixin_onRowClick(item) {
      if (this.tableMixin_hiddenHeaders.length) {
        this.tableMixin_expandedRows =
          this.tableMixin_firstExpandedRowKey !== item[this.tableRowKey] ? [item] : [];
      } else {
        this.$emit('click:row', item);
      }
    },

    tableMixin_expandRow(item) {
      if (this.tableMixin_firstExpandedRowKey !== item[this.tableRowKey]) {
        this.tableMixin_expandedRows = [item];
        this.$emit('row-expand', item);
      } else {
        this.tableMixin_expandedRows = [];
      }
    },

    tableMixin_changePage(page) {
      if (page !== this.pagination.current_page) {
        this.$emit('update:page', page);
      }
    },
  },
};

export default tableMixin;
